<template>
  <div class="page">
    <main class="content">
      <h1 class="title">Partycord</h1>
      <p>
        <span class="temporary">Temporary</span> voice channels made 
        <span class="fun">fun</span> and 
        <span class="easy">easy</span>!
      </p>
      <div class="discord-button-container">
        <a @click="inviteBot" class="discord-button">Invite Bot</a>
        <a @click="joinServer" class="discord-button">Support Server</a>
      </div>
      <br/>
      <img class="logo" src="../assets/logo.png" alt="Partycord Logo">
      <br/>
      <div class="features">
        <div class="feature1">
          <h2>Control Panel</h2>
          <p>Easily control your voice channel & it's connected members from a click of a button!</p>
          <img src="../assets/control_panel.png" alt="Control Panel">
        </div>
        <div class="feature2">
          <h2>User Management</h2>
          <p>Control who can see and join your private voice channels.</p>
          <img src="../assets/user_management2.png" alt="User Management">
        </div>
        <div class="feature3">
          <h2>Channel Settings</h2>
          <p>Change your channel's settings to fit your liking. There is even a menu if you don't want to change one setting at a time! If you like your settings, you can also set them as your default for next time.</p>
          <img src="../assets/channel_management.png" alt="Channel Management">
        </div>
      </div>
      <div class="pfp-container">
        <div>
          <img class="pfp" src="../assets/SleeplessPFP.png" alt="Sleepless Kyru">
          <h1 class="staff">@sleeplesskyru</h1>
          <p>Developer</p>
        </div>

        <div>
          <img class="pfp" src="../assets/SilverPFP.png" alt="Silverhood">
          <h1 class="staff">@silverhood</h1>
          <p>Feature Designer</p>
        </div>
      </div>
      <!-- <iframe class="discord-widget" src="https://discord.com/widget?id=757207186169528382&theme=dark" width="350" height="500" allowtransparency="true" frameborder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe> -->
    </main>
  </div>
</template>

<script>
export default {
  methods: {
    inviteBot() {
      this.$emit('invite-bot');
    },
    joinServer() {
      this.$emit('join-server');
    }
  }
}
</script>

<style scoped>
  h1 {
    background-image: linear-gradient(to right, #00b7ff, #fe5ea1);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .title {
    font-size: 6rem;
  }

  .staff {
    font-size: 3rem;
  }

  p {
    color: #fff;
    font-size: 1.5rem;
    margin-top: 0;
  }

  .temporary {
    color: #00b7ff;
  }

  .fun {
    color: #8e97fa;
  }

  .easy {
    color: #fe5ea1;
  }

  /* .discord-widget {
    margin-top: 10%;
  } */

  .pfp-container {
    text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 10%;
    gap: 75%;
  }

  .pfp-container div {
    flex: 1;
  }

  .pfp {
    width: 10rem;
    border-radius: 100%;
  }

  .logo {
    width: 50%;
    margin-top: 50px;
  }

  .features {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 100%;
  }

  .feature1 {
    margin: 5% 100% 0 20%;
    padding: 0.5em;
    font-size: 2rem;
    text-align: center;
    color: #34daff;
  }

  .feature2 {
    margin: 5% 20% 0 100%;
    padding: 0.5em;
    font-size: 2rem;
    text-align: center;
    color: #8e97fa;
  }

  .feature3 {
    margin: 5% 100% 0 20%;
    padding: 0.5em;
    font-size: 2rem;
    text-align: center;
    color: #fe5ea1;
  }
</style>