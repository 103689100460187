<template>
  <footer class="footer">
    <div class="footer-content">
      <p class="footer-text">©️2020-2024 Kyle Gorr - Partycord. All rights reserved.</p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: #16171c;
  color: #ffffff;
  padding: 20px;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
}

.footer-content {
  display: flex;
  justify-content: right;
  align-items: center;
  padding-right: 30px;
  width: 100%;
}

.footer-text {
  text-align: right;
  font-size: 14px;
}
</style>
