import { createApp } from 'vue'
import router from './router/router'
import App from './App.vue'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { faDiscord } from '@fortawesome/free-brands-svg-icons'
library.add(faBars, faDiscord)

createApp(App)
    .component('fa', FontAwesomeIcon)
    .use(router)
    .mount('#app')