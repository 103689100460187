<template>
  <nav class="navbar">
    <div class="navbar-buttons navbar-buttons-left">
      <fa class="navbar-button" @click="toggleMenu" icon="bars" />
      <router-link to="/" class="navbar-button">Home</router-link>
      <router-link to="/commands" class="navbar-button">Commands</router-link>
      <button @click="joinServer" class="navbar-button">Support Server</button>
      <button @click="becomePatron" class="navbar-button">Become a Patron</button>
    </div>
    <div class="navbar-buttons navbar-buttons-right">
      <button @click="inviteBot" class="navbar-button navbar-invite-button">Invite Bot</button>
    </div>
  </nav>
  <div class="menu-overlay" v-show="showMenu" @click="closeMenu"></div>
  <div class="menu" :class="{ 'menu-open': showMenu }" ref="menuRef">
    <div class="close-button-container">
      <button class="close-button" @click="closeMenu">Close</button>
    </div>
    <div class="menu-content">
      <button class="menu-button">About Us</button>
      <button class="menu-button">FAQ</button>
      <button class="menu-button">Privacy Policy</button>
      <p style="color: #fff; font-weight: bold; font-size: large;">These are placholders for now</p>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showMenu: false
    };
  },
  mounted() {
    document.addEventListener('click', this.handleOutsideClick);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.handleOutsideClick);
  },
  emits: ['invite-bot', 'join-server', 'become-patron'], // Declare the emitted events
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      this.showMenu = false;
    },
    inviteBot() {
      this.$emit('invite-bot');
    },
    joinServer() {
      this.$emit('join-server');
    },
    becomePatron() {
      this.$emit('become-patron');
    },
    handleOutsideClick(event) {
      const menuElement = this.$refs.menuRef;
      const menuButton = document.querySelector('.navbar-button');
      if (menuElement && menuButton && !menuElement.contains(event.target) && !menuButton.contains(event.target)) {
        this.showMenu = false;
      }
    }
  }
};
</script>

<style scoped>
.navbar {
  background-color: #16171c; /* Darker background color */
  color: #fff;
  padding: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.navbar-buttons {
  display: flex;
}

.navbar-buttons-left {
  margin-right: auto;
}

.navbar-buttons-right {
  margin-left: auto;
}

.navbar-button {
  background: none;
  color: #fff;
  padding: 12px 20px; /* Adjust padding to increase button size */
  font-size: 18px; /* Increase font size */
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none; /* Remove link underline */
  border-radius: 20px; /* Apply border radius to make the button oval-shaped */
}

.navbar-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.navbar-invite-button {
  text-decoration: none; /* Remove link underline for invite button */
}

.menu-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  display: none;
}

.menu {
  position: fixed;
  top: 85px;
  left: 0;
  width: 300px;
  height: 100%;
  background-color: #1b1c22;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  z-index: 2;
}

.menu-open {
  transform: translateX(0);
}

.close-button-container {
  display: flex;
  justify-content: flex-end;
}

.close-button {
  background: none;
  color: #fff;
  padding: 12px 20px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  border-radius: 20px;
  margin-bottom: 10px;
}


.close-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.menu-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.menu-button {
  background: none;
  color: #fff;
  padding: 12px 20px;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-decoration: none;
  border-radius: 20px;
  margin-bottom: 10px;
}

.menu-button:hover {
  background-color: rgba(255, 255, 255, 0.1);
}
</style>
