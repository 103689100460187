<template>
  <div id="app">
    <NavbarMenu @invite-bot="inviteBot" @join-server="joinServer" @become-patron="becomePatron" />
    <router-view />
    <FooterMenu @join-server="joinServer" />
  </div>
</template>

<script>
import NavbarMenu from './components/NavbarMenu.vue'
import FooterMenu from './components/FooterMenu.vue'

export default {
  methods: {
    inviteBot() {
      window.open('https://discord.com/api/oauth2/authorize?client_id=756856376201969716&permissions=947325889616&scope=bot%20applications.commands');
    },
    joinServer() {
      window.open('https://discord.com/invite/beaStFd');
    },
    becomePatron() {
      window.open('https://www.patreon.com/partycord');
    }
  },
  components: {
    NavbarMenu,
    FooterMenu
  }
}
</script>

<style src="./assets/styles.css"></style>