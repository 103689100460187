import { createRouter, createWebHistory } from 'vue-router';

// Import the Home and Commands components
import Home from '../views/LandingPage.vue';
import Commands from '../views/CommandsPage.vue';

const router = createRouter({
    history: createWebHistory(),
    routes: [{
            path: '/',
            name: 'Home',
            component: Home,
            meta: {
                title: 'Home'
            }
        },
        {
            path: '/commands',
            name: 'Commands',
            component: Commands,
            meta: {
                title: 'Commands'
            }
        }
    ],
});

router.beforeEach((to, from, next) => {
    document.title = `${to.meta.title}`;
    next();
})

export default router;