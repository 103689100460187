<template>
  <div class="page">
    <h1>Commands</h1>
    <div class="category-buttons">
      <button v-for="(categoryCommands, category) in groupedCommands" :key="category" :class="{ 'selected': selectedCategory === category }" @click="selectedCategory = category">{{ category }}</button>
    </div>
    <div class="grid-container" v-if="selectedCategory">
      <div v-for="command in groupedCommands[selectedCategory]" :key="command.id" class="grid-item" @click="selectedCommand = command" :class="{ 'hovered': command === hoveredCommand }" @mouseover="hoveredCommand = command" @mouseleave="hoveredCommand = null">
        <h2 class="command-name">/{{ command.name }}</h2>
        <p class="description">{{ command.description }}</p>
      </div>
    </div>
    <command-modal v-if="selectedCommand" :command="selectedCommand" @close="selectedCommand = null"></command-modal>
  </div>
</template>

<script>
import CommandModal from '../components/CommandModal.vue';
import commands from '../data/commands.json';

export default {
  components: {
    CommandModal
  },
  data() {
    return {
      commands: [],
      selectedCategory: null,
      selectedCommand: null,
      hoveredCommand: null
    };
  },
  computed: {
    groupedCommands() {
      const grouped = {};
      this.commands.forEach(command => {
        if (command.category != 'Developer') {
          if (!grouped[command.category]) {
            grouped[command.category] = [];
          }
          grouped[command.category].push(command);
        }
      });
      return grouped;
    }
  },
  methods: {
    getCommands() {
      this.commands = commands;
      this.selectedCategory = Object.keys(this.groupedCommands)[0];
    }
  },
  mounted() {
    this.getCommands();
  }
};
</script>

<style scoped>
/* .commands-page {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
} */

h1 {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #fff;
  text-align: center;
  text-shadow: 2px 2px #333;
}

.command-name {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  text-shadow: 1px 1px #333;
}

p {
  font-size: 1em;
  margin: 0;
  color: #fff;
}

.description {
  background-color: #404cce;
  padding: 10px;
  border-radius: 5px;
}

.grid-container {
  display: grid;
  padding: 0 20% 0 20%;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-gap: 20px;
  padding-bottom: 4em;
}

.grid-item {
  border-radius: 5px;
  padding: 20px;
  background-color: #5865F2;
  box-shadow: 0 0 10px rgba(0, 0, 0, 1);
  transition: box-shadow 0.2s ease-in-out, transform 0.2s ease-in-out;
  cursor: pointer;
}

.grid-item:hover, .hovered {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: scale(1.05); /* Scale the element up by 5% */
}

.category-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

button {
  margin: 0 10px;
  padding: 10px 20px;
  border-radius: 20px;
  border: none;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  background-color: #5865F2;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

button:hover, .selected {
  background-color: #404cce;
}
</style>