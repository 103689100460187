<template>
  <div class="modal" @click.self="$emit('close')">
    <div class="modal-content">
      <span class="close" @click="$emit('close')">&times;</span>
      <h2>/{{ command.name }}</h2>
      <p class="description">{{ command.description }}</p>
      <div class="details">
        <h3>Usage:</h3>
        <pre><code>/{{ command.usage }}</code></pre>
        <pre class="details2" v-if="command.usage2"><code>/{{ command.usage2 }}</code></pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    command: Object
  },
  mounted() {
    // Add event listener to close modal when user clicks outside the modal window
    document.addEventListener('click', this.closeModal);
  },
  beforeUnmount() {
    // Remove event listener when component is unmounted
    document.removeEventListener('click', this.closeModal);
  },
  methods: {
    closeModal(event) {
      // Close modal if user clicks outside the modal window or on the "x" button
      if (event.target === this.$el || event.target.classList.contains('close')) {
        this.$emit('close');
      }
    }
  }
};
</script>

<style scoped>
.modal {
  display: block;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
}

.modal-content {
  background-color: #5865F2;
  margin: 15% auto;
  padding: 20px;
  border-radius: 20px;
  width: 80%;
  max-width: 600px;
  color: #fff;
}

.text-border {
  border: 1px solid black;
  padding: 5px;
}

code {
  background-color: #36393f;
  border: 1px solid #1d1f22;
  border-radius: 4px;
  font-family: Consolas, monospace;
  font-size: 1em;
  color: #fff;
  padding: 2px 4px;
}

h2 {
  text-shadow: 1px 1px #333;
}

pre, h3 {
  margin-top: 1em;
}

.description {
  background-color: #404cce;
  padding: 10px;
  margin-top: 1em;
  border-radius: 5px;
}

.close {
  color: #fff;
  float: right;
  font-size: 28px;
  font-weight: bold;
  transition-duration: 0.2s;
}

.close:hover,
.close:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}
</style>